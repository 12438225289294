import React from 'react'
import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { SimplePage } from 'Shared/SimplePage';
import LogInForm from './LogInForm'

const LogIn = () => (
  <SimplePage icon='sign in' title='Log In' centered>
    <Segment.Group>
      <Segment>
        <LogInForm/>
      </Segment>
      <Segment style={{textAlign:'center'}}>
        Don't have an account? <Link to="/">Sign Up</Link>
      </Segment>
    </Segment.Group>
  </SimplePage>
);

export default LogIn;
