import React, { useState, useCallback, useContext } from 'react'
import { Form, Message, Button } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router'

import API from 'Api'
import { useRequest, useFields } from 'Shared/Hooks';
import { Notebook } from 'Shared/NotebookContext'

export const NotebookForm = () => {
  const {notebook, load} = useContext(Notebook)
  const {uid, nid} = useParams();
  const [loading, error, run] = useRequest({})
  const [fields, handleChange] = useFields(notebook)
  const [redirect, setRedirect] = useState('');

  const handleSubmit = useCallback(() => {
    run(API.updateNotebook(fields), ()=>{
      setRedirect(`/u/${uid}/n/${nid}`)
      load();
    })
  }, [nid, uid, fields, run, load])

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  const {title} = fields;

  return <Form error name="edit-notebook" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    {/* TODO: Status field for admins? pause/trash functions? */}
    <Form.Input
      name="title"
      label="Title"
      placeholder="Notebook Name"
      required
      value={title}
      style={{maxWidth:'20em'}}
      onChange={handleChange} />
    <Button primary type="submit">Save</Button>
  </Form>
}
