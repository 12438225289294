import React from 'react'
import { Container, Grid, Header, Icon, Message, Loader } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import './page.less'

export const SimplePage = ({title, icon, centered, loading, error, children, headerStyle}) => (
  <>
    <Container className='page'>
      {title && !loading ? 
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        : false}

      <Content centered={centered}>
        <Header as='h1' style={headerStyle}>
          {icon ? <Icon name={icon}/> : false }{title}
        </Header>

        {error ? <Message negative>{error}</Message> : false}

        {loading ? <Loader active inline='centered'/> : children}
      </Content>
    </Container>
  </>
);

export const Content = ({centered, children}) => (
  centered ? 
  <Grid centered>
    <Grid.Column mobile={16} tablet={14} computer={10}>
      {children}
    </Grid.Column>
  </Grid>
  : children
)
