import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { PrivateRoute, NoMatch, AdminRoute } from './Helpers'

import Home from './Home'
import LogIn from './LogIn'
import Verify from './Verify'

import { About } from './About'
import { Privacy } from './Privacy'
import { Contact } from './Contact'

import { Account } from './Account'
import Notebook from './Notebook'

import { Admin } from './Admin'

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/login" component={LogIn}/>
    <Route exact path="/verify/:code/:n" component={Verify}/>

    <Route exact path="/about" component={About}/>
    <Route exact path="/privacy" component={Privacy}/>
    <Route exact path="/contact" component={Contact}/>

    <PrivateRoute exact path="/u/:uid" component={Account}/>
    <PrivateRoute path="/u/:uid/n/:nid" component={Notebook}/>

    <AdminRoute path="/admin" component={Admin}/>

    <Route component={NoMatch} />
  </Switch>
)

export default Routes;
