import React, { useEffect, useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Placeholder, Message, Segment, Button, Header, Loader, Table } from 'semantic-ui-react'

import API from 'Api'
import { useRequest } from 'Shared/Hooks'
import { PromptForm } from 'Shared/PromptForm'
import { Toggler } from 'Shared/Toggler'
import { Notebook } from 'Shared/NotebookContext'

const Prompts = () => {
  const {notebook, setNotebook} = useContext(Notebook)
  const [loading, error, run, prompts] = useRequest([])
  const {nid} = useParams()
  const [add, setAdd] = useState(false)

  const loadPrompts = useCallback(()=>{
    setAdd(false)
    run(API.getPrompts(nid))
  }, [run, nid])

  useEffect(loadPrompts, [loadPrompts])

  const pause = useCallback(() => {
    return API.updateNotebook(Object.assign({}, notebook, {status: 'paused'})).then(setNotebook)
  }, [notebook, setNotebook])

  const activate = useCallback(() => {
    return API.updateNotebook(Object.assign({}, notebook, {status: 'active'})).then(setNotebook)
  }, [notebook, setNotebook])

  return <>
    <div style={{marginBottom:'1.5em'}}>
      <Toggler
        label='Send Me Prompts'
        init={()=>(Promise.resolve(notebook.status === 'active'))}
        onCheck={activate}
        onUncheck={pause}/>
    </div>
    {error ? <Message negative content={error}/> : false}
    {loading ? <PromptPlaceholder/> : false }
    {prompts.length === 0 && !loading ? <p>No saved prompts to show...</p> : false }
    {!loading && notebook.status === 'active' && prompts.map(p=>{
      return <Segment key={p.id}>
        <PromptForm defaultValue={p} toggleEdit reload={loadPrompts}/>
      </Segment>
    })}
    {add ? 
      <Segment>
        <PromptForm reload={loadPrompts}/>
      </Segment>
      : <Button onClick={()=>{setAdd(true)}} icon='plus' content='New Prompt'/>}
    <Records/>
  </>
}

export default Prompts;

const PromptPlaceholder = () => (
  <Segment>
    <Placeholder style={{marginBottom:'1em'}}>
      <Placeholder.Paragraph>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </Segment>
)

const Records = () => {
  const [loading, error, run, records] = useRequest([])
  const {nid} = useParams()

  const load = useCallback(()=>{
    run(API.getRecords(nid))
  }, [run, nid])

  useEffect(load, [load])

  return <>
    <Header as='h3'>Previous Prompts</Header>
    {error ? <Message negative content={error}/> : false}
    {loading ? <Loader active inline/> : false}
    <Table compact>
      <Table.Body>
        {!loading && records.map(r=>{
          return <Table.Row key={r.id}>
            <Table.Cell content={r.created_at ? <>{r.created_at.substring(0,10)}&nbsp;UTC</> : false}/>
            <Table.Cell content={r.content}/>
          </Table.Row>
        })}
      </Table.Body>
    </Table>
  </>
}
