import { Select } from "semantic-ui-react"

export const PromptTypes = [
  'gratitude',
  // 'simple',
  'custom'
]

export const PromptTypeSelect = (props) => {
  return <Select
    {...props}
    options={PromptTypes.map(type=>({key: type, value: type, text: type}))}
    />
}
