import React, { useContext } from 'react'
import { Redirect } from 'react-router'
import { Route, useLocation } from 'react-router-dom'
import { Loader, Container, Dimmer } from 'semantic-ui-react'

import { SimplePage } from 'Shared/SimplePage'
import { User } from 'Shared/UserContext'

export const PrivateRoute = (props) => (
  <LimitedRoute {...props} check={(user)=>(user.id > 0)} />
);

export const AdminRoute = (props) => (
  <LimitedRoute {...props} check={(user)=>(user.role === 'admin')} />
);

export const LimitedRoute = ({ check, component: C, render, path, ...routeProps }) => {
  const location = useLocation();
  const {user, userLoading} = useContext(User)

  return (
    <Route {...routeProps} render={() => {
      if (userLoading) {
        return <BigLoader/>
      }

      // if they fail the check, attempt to log them in
      if (!check(user)) {
        if (user.id > 0) {
          return <NoMatch/>
        } else {
          return (
            <Redirect to={{
              pathname: '/login',
              state: { from: location }
            }} />
          );
        }
      }

      if (render) {
        return render();
      }

      return <C/>
    }} />
  )
}

export const NoMatch = () => (
  <SimplePage icon='cancel' title='Not Found'>
    <p>The page you are trying to view does not exist!</p>
  </SimplePage>
);

const BigLoader = () => (
  <Container>
    <Dimmer active inverted>
      <Loader size="big"/>
    </Dimmer>
  </Container>
)
