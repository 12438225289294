export default class API {
  // SESSION
  static logout = () => (
    _delete('/api/session')
  )
  static login = body => (
    _post('/api/session', body)
  )

  // RESETS
  static reset = body => (
    _post('/api/reset', body)
  )
  static checkReset = (code) => (
    _get(`/api/reset/${code}`)
  )

  // USER
  static signup = body => (
    _post('/api/user', body)
  )
  static whoami = () => (
    _get('/api/user')
  )
  static verify = body => (
    _post('/api/user/verify', body)
  )
  static updatePassword = body => (
    _put('/api/user/password', body)
  )
  static getUser = (id) => (
    _get(`/api/user/${id}`)
  )
  static getUsers = () => (
    _get('/api/users')
  )

  // NOTES
  static getUserNotebooks = (id) => (
    _get(`/api/user/${id}/notebooks`)
  )

  // NOTES
  static getNotebook = (nid) => (
    _get(`/api/notebook/${nid}`)
  )
  static updateNotebook = body => (
    _put('/api/notebook', body)
  )
  static getNotes = (nid) => (
    _get(`/api/notebook/${nid}/notes`)
  )
  static getNote = (id) => (
    _get(`/api/note/${id}`)
  )
  static createNote = body => (
    _post('/api/note', body)
  )
  static updateNote = body => (
    _put('/api/note', body)
  )
  static deleteNote = id => (
    _delete(`/api/note/${id}`)
  )

  // PROMPTS
  static getPrompts = (id) => (
    _get(`/api/notebook/${id}/prompts`)
  )
  static updatePrompt = body => (
    _put('/api/prompt', body)
  )
  static createPrompt = body => (
    _post('/api/prompt', body)
  )
  static deletePrompt = id => (
    _delete(`/api/prompt/${id}`)
  )

  // RECORDS
  static getRecords = (id) => (
    _get(`/api/notebook/${id}/records`)
  )

  // MISC
  static getStats = () => (
    _get(`/api/stats`)
  )
}

// internal utils
const _get = (url, body) => {
  return _fetch('GET', url, body);
}

const _post = (url, body) => {
  return _fetch('POST', url, body);
}

const _delete = (url, body) => {
  return _fetch('DELETE', url, body);
}

const _put = (url, body) => {
  return _fetch('PUT', url, body);
}

const _fetch = (method, url, body) => {
  return fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: {
      // CSRF prevention
      // https://www.owasp.org/index.php/Cross-Site_Request_Forgery_(CSRF)_Prevention_Cheat_Sheet#Use_of_Custom_Request_Headers
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
  .then(resp => resp.json())
  .then(result => {
    if (result.error) {
      return Promise.reject(result.error);
    }
    return Promise.resolve(result);
  })
  .catch(error => {
    if (error instanceof SyntaxError) {
      // show something human readable if the backend is down
      // and JSON.parse fails (nginx 502 returns html)  
      error = new Error("The server is unable to respond to this request right now.")
    }
    return Promise.reject(error.toString());
  });
}
