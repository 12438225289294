import API from 'Api'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Header, Loader, Message, Table } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import { SimplePage } from 'Shared/SimplePage'

export const Admin = () => {
  return <SimplePage title='Admin'>
    <Header as='h3'>Users</Header>
    <Users/>
  </SimplePage>
}

export const Users = () => {
  const [loading, error, run, users] = useRequest([], {loading: true})

  useEffect(()=>{
    run(API.getUsers())
  }, [run])

  if (loading) {
    return <Loader inline active/>
  }

  if (error) {
    return <Message warning content={error}/>;
  }

  return <Table compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell textAlign='center' content='ID'/>
        <Table.HeaderCell content='Email'/>
        <Table.HeaderCell content='Notes'/>
        <Table.HeaderCell content='Latest Note'/>
        <Table.HeaderCell content='Status'/>
        <Table.HeaderCell content='Created'/>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {users.map((u)=>{
        return <Table.Row key={u.id}>
          <Table.Cell collapsing>
            <Button compact as={Link} to={`/u/${u.id}`}>{u.id}</Button>
          </Table.Cell>
          <Table.Cell>
            <a href={`mailto:${u.email}`}>{u.email}</a>
          </Table.Cell>
          <Table.Cell collapsing content={u.notes ? u.notes : false}/>
          <Table.Cell collapsing content={u.latest_note ? u.latest_note.substring(0,10) : false}/>
          <Table.Cell collapsing content={u.status}/>
          <Table.Cell collapsing content={u.created_at.substring(0,10)}/>
        </Table.Row>
      })}
    </Table.Body>
  </Table>
}
