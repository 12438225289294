import React, { useEffect, useCallback, useContext, useState, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Form, Message, Button, TextArea, Select, Icon, Confirm } from 'semantic-ui-react'
import { RRule } from 'rrule'

import { SimplePage } from './SimplePage'

import API from 'Api'
import { useFields, useRequest } from 'Shared/Hooks';
import { PromptTypeSelect } from 'Shared/PromptTypes';
import { Weekdays } from './Weekdays';

import './prompt-form.less'
import { Notebook } from './NotebookContext'

export const PromptCreate = () => {
  return <SimplePage centered title='Create a Prompt'>
    <PromptForm/>  
  </SimplePage>
}

export const PromptForm = ({defaultValue, reload, toggleEdit}) => {
  const [loading, error, run] = useRequest({})
  const [fields, handleChange] = useFields({prompt: defaultValue})
  const [saved, setSaved] = useState(false)
  const [editing, setEditing] = useState(!toggleEdit)
  const {notebook} = useContext(Notebook)

  const {prompt} = fields;

  const handleDelete = useCallback(() => {
    run(API.deletePrompt(prompt.id), reload)
  }, [run, prompt, reload])

  const handleSubmit = useCallback(() => {
    if (prompt && prompt.id) {
      run(API.updatePrompt(prompt), ()=>{
        setSaved(true)
        setEditing(false)
        setTimeout(()=>{
          setSaved(false)
        }, 2000);
      })
    } else {
      const body = Object.assign({}, prompt, {notebook_id: notebook.id});
      run(API.createPrompt(body), reload)
    }
  }, [run, prompt, notebook, reload])

  return <Form success error name="prompt-form" loading={loading} onSubmit={handleSubmit}>
    <Message error>{error}</Message>
    {saved ? <Message style={{marginTop:0}} success>You prompt settings were saved.</Message> : false}
    <PromptFormFields name="prompt" editing={editing} defaultValue={prompt} onChange={handleChange}/>
    {!editing ? <Button onClick={()=>{setEditing(true)}} content='Edit'/> : <>
      <Button primary type="submit">Save</Button>
      {prompt && prompt.id ? <ConfirmButton action={handleDelete} confirmText='Delete' icon='trash'/> : <i>&nbsp;&nbsp;Unsaved!</i> }
    </> }
  </Form>
}

const ConfirmButton = ({action, confirmText, ...props}) => {
  const [open, setOpen] = useState(false)
  return <>
    <Button {...props} onClick={(e)=>{
      e.preventDefault();
      setOpen(true)
    }}/>
    <Confirm
      size='mini'
      confirmButton={confirmText}
      open={open}
      onCancel={()=>{
        setOpen(false)
      }}
      onConfirm={()=>{
        setOpen(false)
        action()
      }}
    />
  </>
}

const parsePrompt = (prompt, getOptions) => {
  if (!prompt) {
    prompt = defaultPrompt;
  }
  const options = RRule.parseString(prompt.rrule);
  if (getOptions) {
    return Object.assign({}, options, {
      time: moment.utc(options.dtstart).local().hours(),
    });
  }
  return Object.assign({}, prompt, {rrule: new RRule(options)});
}

export const defaultPrompt = {
  type: 'gratitude',
  rrule: new RRule({
    freq: RRule.WEEKLY,
    byweekday: [RRule.MO, RRule.TH],
    dtstart: moment().hour(17).minute(0).second(0).utc().toDate()
  }).toString(),
  body: 'Would you like to add an entry to your Email Notebook?'
};

export const PromptFormFields = ({name, size, defaultValue, onChange, editing}) => {
  const [fields, handleChange] = useFields(parsePrompt(defaultValue));
  const [options, handleOptionChange] = useFields(parsePrompt(defaultValue, true))

  // update rrule on option change
  useEffect(()=>{
    const rule = new RRule({
      freq: options.freq,
      byweekday: _.isArray(options.byweekday) && options.byweekday.length ? options.byweekday : null,
      dtstart: moment().hour(options.time).minute(0).second(0).utc().toDate(),
    });

    handleChange(null, {name: 'rrule', value: rule})
  }, [options, handleChange])

  // reset weekday list
  useEffect(()=>{
    if (options.freq !== RRule.WEEKLY) {
      handleOptionChange(null, {name: 'byweekday', value: []})
    } else {
      handleOptionChange(null, {name: 'byweekday', value: [RRule.MO, RRule.TH]})
    }
  }, [options.freq, handleOptionChange])

  // broadcast values up
  useEffect(()=>{
    const transformed = Object.assign(fields, {rrule: fields.rrule.toString()})
    onChange(null, {name, value: transformed})
  }, [fields, name, onChange])

  const {type, body} = fields;
  const {freq, time, byweekday} = options;

  return <>
    <div className={`prompt-form ${size}`}>
      <div style={{marginBottom:'1em'}}>
        Get a&nbsp;
        <PromptTypeSelect
          compact
          name="type"
          value={type}
          onChange={handleChange}
          disabled={!editing}
          />
        &nbsp;prompt&nbsp;
        <Select
          compact
          name='freq'
          value={freq}
          onChange={handleOptionChange}
          options={RecurrenceOptions}
          disabled={!editing}
          />
        &nbsp;at&nbsp;
        <span style={{display:'inline-block'}}>
          <Select
            compact
            name='time'
            value={time}
            onChange={handleOptionChange}
            options={TimeOptions}
            disabled={!editing}
            />
          &nbsp;<span style={{color: '#999'}}>{moment.tz(moment.tz.guess()).format('z')}</span>
        </span>
      </div>
      {freq === RRule.WEEKLY ? 
        <Weekdays name='byweekday' editing={editing} value={byweekday} onChange={handleOptionChange}/>
        : false }
      {type === 'custom' && editing ? <TextArea
          required
          placeholder='Your custom writing prompt'
          name="body"
          value={body}
          onChange={handleChange}
          style={{marginBottom:'1em'}}
          />
          : <PromptPreview type={type} body={body}/> }
    </div>
  </>
}

const RecurrenceOptions = [{
  key: RRule.DAILY,
  value: RRule.DAILY,
  text: 'every day',
}, {
  key: RRule.WEEKLY,
  value: RRule.WEEKLY,
  text: 'every week',
}];

const TimeOptions = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].map(hr=>{
  return {
    key: hr,
    value: hr,
    text: (hr % 12 === 0 ? '12' : hr % 12) + (hr > 11 ? ' PM' : ' AM')
  }
})

const PromptPreview = ({type, body}) => {
  const [prompt, setPrompt] = useState(getPrompt(type, body))
  const timer = useRef()

  const randomize = useCallback(() => {
    setPrompt(getPrompt(type, body))
  }, [type, body]);

  const reset = useCallback(() => {
    randomize();
    clearInterval(timer.current)
    timer.current = setInterval(randomize, 8000);
    return () => clearInterval(timer.current);
  }, [randomize]);

  // init
  useEffect(reset, [reset])

  return <p style={{
    color: '#666',
    fontSize:'1rem',
    marginBottom:'1.25rem',
    marginTop:'-1em',
    fontStyle:'italic',
    }}>
      {prompt}&nbsp;
      {type === 'gratitude' ? 
        /* eslint-disable-next-line */
        <a title='Show another prompt' style={{textDecoration:'none',cursor:'pointer'}} onClick={reset}><Icon size='small' name='refresh'/></a> 
        : false}
  </p>
}

const getPrompt = (type, body) => {
  if (type === 'custom') {
    return body;
  }
  return _.sample(promptMap[type]);
}

export const promptMap = {
  'simple': ['Would you like to add an entry to your Email Notebook?'],
  'gratitude': [
    "Who are some colleagues that you feel affection towards, and why?",
    "What marvels of modern technology do you most appreciate?",
    "Who is the last person you ran into unexpectedly, and were happy to see?",
    "In what ways has life for the average person improved during your lifetime?",
    "What is your favorite movie of all time? What makes it good?",
    "Is there something that you'd like to thank your past self for doing?",
    "What's a good book, movie, or tv show you saw recently?",
    "Which of your senses do you appreciate the most?",
    "What do you feel gratitude for during the week?",
    "Who are some friends that you feel affection towards, and why?",
    "What are some good things about this planet, on a cosmic scale?",
    "How many people's efforts were combined in the supply chain of your last meal?",
    "What is your favorite type of food?",
    "What is a time you had incredible luck?",
    "What are some things you take for granted, but should be more aware of?",
    "What are some obstacles you successfully overcame in the past?",
    "Share a little bit about 3 events that you appreciated in the past year.",
    "In what ways has life for the average person improved since the middle ages?",
    "What is something or someone makes you laugh out loud?",
    "What are you grateful for today, and why?",
    "What is your favorite place in nature?",
    "What is a small favor that you appreciated more than the giver likely realized?",
    "What do you feel gratitude for in the morning?",
    "What vital functions does your body do for you automatically, every day?",
    "What's your favorite piece of clothing, and why?",
    "What marvels of modern medicine do you most appreciate?",
    "Who is a partner (past or present) that you feel affection towards, and why?",
    "What is something you like about the weather this time of year?",
    "Write a short note to a mentor, thanking them for what they've given you.",
    "What is your favorite aspect of your bedroom?",
    "Write a note to a historical or present-day figure who positively impacted your life.",
    "What was a positive event that happened this week? What if it hadn't happened?",
    "What do you feel gratitude for on the weekend?",
    "How easily can you access basic needs, like food, water, and shelter? Imagine you lived 100,000 years ago, instead.",
    "What is something that you own that brings you joy?",
    "Is there someone in your life who has helped you through a difficult time? How did they support you?",
    "What is your favorite memory from childhood, and what do you appreciate about it?",
    "What is something that you have accomplished recently that you are proud of?",
    "What is something beautiful that you saw or experienced recently?",
    "What is something new that you learned recently that you found interesting?",
    "Who is someone you admire, and what do you appreciate about them?",
    "How have you grown as a person, and what are you grateful for in that growth?",
    "What is something you are looking forward to?",
  ]
}
