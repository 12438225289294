import React, {useEffect, useState} from 'react'
import { Loader, Checkbox, Label } from 'semantic-ui-react'
import { useRequest } from './Hooks'

// all of these props are promises that set/unset check
export const Toggler = ({ init, onCheck, onUncheck, ...props }) => {
  const [checked, setChecked] = useState(false)
  const [loading, error, run] = useRequest({}, {loading: true});

  useEffect(()=>{
    run(init(), setChecked)
  }, [run, init]);

  const handleChange = (e, {checked}) => {
    if (checked) {
      run(onCheck(), ()=>{
        setChecked(true)
      })
    } else {
      run(onUncheck(), ()=>{
        setChecked(false)
      })
    }
  }

  return <>
    <Checkbox toggle disabled={loading} checked={checked} onChange={handleChange} {...props}/>
    <Loader style={{left:'0.5em'}} size='tiny' inline active={loading}/>
    {error ? <Label size='tiny' color='red'>{error}</Label> : false}
  </>
}
