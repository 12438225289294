import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Nav from 'Nav/Nav'
import Routes from 'Routes/Routes'

import { WithUser } from 'Shared/UserContext'

const App = () => (
  <WithUser>
    <Helmet
      defaultTitle="Email Notebook"
      titleTemplate="%s | Email Notebook"
    >
      <meta property="og:image" content='/og.png' />
      <meta property="og:image:alt" content='Email notebook: Create and maintain a writing habit.' />
      <meta property="og:title" content='Email Notebook' />
      <meta property="og:description" content='Create and maintain a writing habit.' />
    </Helmet>
    <Router>
      <div id="wrapper">
        <Nav/>
        <Routes/>
      </div>
    </Router>
  </WithUser>
);

export default App;
