import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Header, Placeholder, Message, Icon } from 'semantic-ui-react'

import API from 'Api'
import { useRequest } from 'Shared/Hooks'
import { NoteDate } from 'Shared/NoteDate'
import { User } from 'Shared/UserContext'
import { Toggler } from 'Shared/Toggler'
import { promptMap } from 'Shared/PromptForm'

const Notes = () => {
  const {user} = useContext(User)
  const {uid, nid} = useParams()
  const isAdmin = user.id !== Number(uid);
  const [loading, error, run, notes] = useRequest([])
  const [obscured, setObscured] = useState(isAdmin)

  useEffect(()=>{
    run(API.getNotes(nid))
  }, [run, nid])

  return <>
    {isAdmin ? <Toggler 
      label='Obscured'
      style={{marginBottom:'1em'}}
      init={()=>(Promise.resolve(obscured))}
      onCheck={()=>{setObscured(true); return Promise.resolve();}}
      onUncheck={()=>{setObscured(false); return Promise.resolve();}}
      /> : false}
    {error ? <Message negative content={error}/> : false}
    {loading ? <NotePlaceholder/> : false }
    {notes.length === 0 && !loading ? <p>No notes to show, yet...</p> : false }
    {notes.map(n=>{
      return <Note key={n.id} {...n} obscured={obscured}/>
    })}
  </>
}

export default Notes;

const Note = ({id, subject, clean_text, created_at, obscured, content_id}) => {
  const {uid, nid} = useParams()
  const subj = obscured ? rot13(subject) : subject;
  const ct = obscured ? rot13(clean_text) : clean_text;

  return <div key={id} style={{marginBottom: '2em'}}>
    <Header as='h3' style={subj || content_id >= 0 ? {marginBottom: '0.25rem'} : {}}>
      {subj ? subj : NoteDate(created_at)} &nbsp;
      <Link 
        to={`/u/${uid}/n/${nid}/notes/${id}`} 
        title='Edit this note'
        style={{textDecoration:'none'}}>
        <Icon name='pencil'/>
      </Link>
    </Header>
    {subject || content_id >= 0 ?
      <p style={{color: '#999', fontSize: '0.8em', marginTop:0, marginBottom: '0.25rem'}}>
        {content_id >= 0? <em>{promptMap.gratitude[content_id]}</em> : false}
        {subject && content_id >= 0 ? <br/> : false}
        {subject ? NoteDate(created_at) : false}
      </p> : 
      false}
    <p style={{whiteSpace:'pre-wrap'}}>{ct}</p>
  </div>
}

const rot13 = (str) => {
  var input     = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var output    = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
  var index     = x => input.indexOf(x);
  var translate = x => index(x) > -1 ? output[index(x)] : x;
  return str.split('').map(translate).join('');
}

const NotePlaceholder = () => (
  <Placeholder style={{marginBottom:'1em'}}>
    <Placeholder.Header>
      <Placeholder.Line length='medium'/>
    </Placeholder.Header>
    <Placeholder.Paragraph>
      <Placeholder.Line length='short'/>
    </Placeholder.Paragraph>
    <Placeholder.Paragraph>
      <Placeholder.Line length='very long'/>
      <Placeholder.Line length='full'/>
      <Placeholder.Line length='very long'/>
      <Placeholder.Line />
    </Placeholder.Paragraph>
  </Placeholder>
)
