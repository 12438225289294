import { useContext } from 'react'

import { User } from 'Shared/UserContext'

export const Admin = ({children}) => {
  const {user} = useContext(User)
  return user.role === 'admin' ? children : false;
}

export const LoggedIn = ({children}) => {
  const {user} = useContext(User)
  return user.id > 0 ? children : false;
}

export const Anon = ({children}) => {
  const {user} = useContext(User)
  return user.id === 0 ? children : false;
}
