import React, { useContext } from 'react'
import { Header, Image } from 'semantic-ui-react';

import { SimplePage } from 'Shared/SimplePage';
import { Anon, LoggedIn } from 'Shared/Roles';

import SignUpForm from './SignUpForm';
import { Notebooks } from 'Routes/Account';
import { User } from 'Shared/UserContext';

const Home = () => {
  const {user} = useContext(User) 
  return <SimplePage centered title='Email Notebook' headerStyle={{marginBottom:0,textAlign:'center'}}>
    <center>
      <Image centered style={{maxWidth:'50%'}} src='/email-notebook.svg'/>
      <Anon>
        <p style={{marginBottom:'2em'}}>Create and maintain a <a target="_blank" href="https://www.lesswrong.com/posts/xYnnRmMmGRAwZoY25/for-happiness-keep-a-gratitude-journal" rel="noreferrer">gratitude journal</a>, diary, or other writing habit.<br/>
        Enter your email below to receive prompts in your inbox.<br/>
        Replies will be saved to your private account.</p>
        <SignUpForm/>
      </Anon>
      <LoggedIn>
        <Header as='h3'>My Notebooks</Header>
        <Notebooks user={user}/>
      </LoggedIn>
    </center>
  </SimplePage>
}

export default Home;
