import API from "Api"
import { useEffect } from "react"
import { Loader, Message, Statistic } from "semantic-ui-react"
import { Emoji } from "Shared/Emoji"
import { useRequest } from "Shared/Hooks"
import { SimplePage } from "Shared/SimplePage"

export const About = () => {
  return <SimplePage centered icon='question circle' title='About'>
    <p>Email Notebook is built and maintained by me, <a href="https://github.com/karlkeefer/" target="_blank" rel="noreferrer">Karl Keefer</a>.</p>

    <p>After being <a href="https://www.lesswrong.com/posts/xYnnRmMmGRAwZoY25/for-happiness-keep-a-gratitude-journal" target="_blank" rel="noreferrer">convinced of the value of maintaining a gratitude journal</a>, I still hadn't managed to make a habit of the practice for <i>years</i>. At the same time, I have a consistent writing habit when maintaining my email inbox... </p>

    <p><Emoji>💡</Emoji>What if I could write my gratitude journal by replying to emails?</p>

    <Stats/>
  </SimplePage>
}

const Stats = () => {
  const [loading, error, run, stats] = useRequest({})
  
  useEffect(()=>{
    run(API.getStats())
  }, [run])

  if (loading) {
    return <Loader inline active/>
  }

  if (error) {
    return <Message negative content={error}/>
  }

  return <Statistic.Group widths={4} style={{marginTop:'2em'}}>
    <Statistic value={age()} label='Days Online'/>
    <Statistic value={stats.users} label='Users'/>
    <Statistic value={stats.notebooks} label='Notebooks'/>
    <Statistic value={stats.notes} label='Notes'/>
  </Statistic.Group>
}

const age = () => {
  const launch = new Date("2022-01-07");
  return Math.round((new Date().getTime() - launch.getTime()) / (1000 * 3600 * 24))
}
