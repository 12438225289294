import React, { useContext, useEffect, useState } from 'react'
import { Menu, Container, Button } from 'semantic-ui-react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'

import { User } from 'Shared/UserContext'
import { LoggedIn, Anon, Admin } from 'Shared/Roles';

import './responsive.css'

// helper for semanticUI + react-router
export const MenuLink = props => (
  <NavLink
    {...props}
    activeClassName="active"
  />
);

const Nav = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false)
  const {user, handleLogout} = useContext(User)

  useEffect(()=>{
    setOpen(false);
  }, [location])

  const menuClass = open ? '' : 'hidden';

  return <Menu borderless stackable fixed="top" style={{boxShadow:'none'}}>
    <Container>
      <Button id="toggler" fluid icon='sidebar' onClick={() => setOpen(!open)}/>
      <Menu.Menu className={menuClass} position="left" id="override">
        <Menu.Item as={MenuLink} exact to="/" className='logo-nav' title='Email Notebook'>
          <img src='/email-notebook-simple.svg' alt='Email Notebook'/> <span className="text">Email Notebook</span>
        </Menu.Item>
        <Menu.Item as={MenuLink} to='/about' content='About'/>
        <Menu.Item as={MenuLink} to='/privacy' content='Privacy'/>
        <Menu.Item as={MenuLink} to='/contact' content='Contact'/>
      </Menu.Menu>
      <Menu.Menu className={menuClass} position="right">
        <Anon>
          <Menu.Item as={MenuLink} exact to={{pathname:"/login", state:{from:location}}} name="Log In" />
        </Anon>
        <Admin>
          <Menu.Item as={MenuLink} to={`/admin`} content='Admin'/>
        </Admin>
        <LoggedIn>
          <Menu.Item as={MenuLink} to={`/u/${user.id}`} content='My Notebooks'/>
          <Menu.Item link={true} onClick={handleLogout} content="Log Out"/>
        </LoggedIn>
      </Menu.Menu> 
    </Container>
  </Menu>
}

export default Nav;
