import React, { useEffect } from 'react'
import { Form, Checkbox } from 'semantic-ui-react'
import _ from 'lodash'
import { useFields } from './Hooks';

const isChecked = (list, item) => {
  if (!_.isArray(list)) {
    return false;
  }
  return _.map(list, 'weekday').includes(item);
}

const transformState = (value) => {
return {
    'Mo': isChecked(value, 0),
    'Tu': isChecked(value, 1),
    'We': isChecked(value, 2),
    'Th': isChecked(value, 3),
    'Fr': isChecked(value, 4),
    'Sa': isChecked(value, 5),
    'Su': isChecked(value, 6)
  }
}

const dayValue = {
  'Mo': 0,
  'Tu': 1,
  'We': 2,
  'Th': 3,
  'Fr': 4,
  'Sa': 5,
  'Su': 6
}

const asArray = daysMap => {
  return Object.keys(daysMap).filter(d => {
    return daysMap[d] !== false
  }).map(d => {
    return dayValue[d];
  });
}

export const Weekdays = ({name, value, onChange, editing}) => {
  const [days, handleChange, setFields] = useFields(transformState(value))

  useEffect(()=>{
    onChange(null, {
      name: name,
      value: asArray(days)
    })
  }, [name, days, onChange])

  useEffect(() => {
    // detect an upstream change
    if (asArray(days).length === 0 && value.length !== 0) {
      setFields(transformState(value))
    }
  }, [value, days, setFields])

  return <Form.Field>
    { Object.keys(days).map(day => (
      <Checkbox
        style={{marginRight:'1em',marginBottom:'1em'}}
        key={day}
        label={day}
        name={day}
        checked={days[day]}
        disabled={!editing}
        value={dayValue[day]}
        onChange={handleChange}
      />
    ))}
  </Form.Field>
}
