import React, { useCallback } from 'react'
import { Form, Button, Message } from 'semantic-ui-react'

import API from 'Api'
import { useRequest, useFields } from 'Shared/Hooks';
import { useLocation } from 'react-router-dom';

const LogInForm = () => {
  const location = useLocation()
  const [loading, error, run, result] = useRequest(false)
  const [fields, handleChange] = useFields({
    email: '',
    redirect: location.state && location.state.from && location.state.from.pathname && location.state.from.pathname !== '/' ? location.state.from.pathname : ''
  })

  const handleSubmit = useCallback(() => {
    run(API.login(fields));
  }, [run, fields])

  if (result) {
    return <Message success>Check your email for a Login Link.</Message>
  }

  const { email } = fields;

  return (
    <Form error name="login" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <Form.Input
        size="big"
        name="email"
        type="email"
        placeholder="Email"
        required
        value={email}
        onChange={handleChange} />
      <Button primary fluid size="huge" type="submit">Send Me a Login Link</Button>
    </Form>
  )
}

export default LogInForm;
