import React, { useEffect, useState, useCallback } from 'react'

import API from 'Api'
import { Message } from 'semantic-ui-react';
import { useRequest } from './Hooks';
import { SimplePage } from './SimplePage';

export const emptyNotebook = {
  id: 0,
  title: '',
  status: 0
};

export const Notebook = React.createContext({
  notebook: emptyNotebook,
  loading: false,
  error: false,
  load: () => {},
  setNotebook: () => {}
});

export const WithNotebook = ({children, nid}) => {
  const [notebook, setNotebook] = useState({})
  const [loading, error, run] = useRequest({})

  const load = useCallback(()=>{
    run(API.getNotebook(nid), setNotebook);
  }, [run, nid])

  useEffect(load, [load])

  if (error) {
    return <SimplePage><Message warning content={error}/></SimplePage>
  }
  
  return (
    <Notebook.Provider value={{notebook, loading, error, load, setNotebook}}>
      {children}
    </Notebook.Provider >
  );
}
