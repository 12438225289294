import API from 'Api'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Header, Loader, Message } from 'semantic-ui-react'
import { useRequest } from 'Shared/Hooks'
import { SimplePage } from 'Shared/SimplePage'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

export const Account = () => {
  const {uid} = useParams()
  const [loading, error, run, user] = useRequest({})

  useEffect(()=>{
    run(API.getUser(uid))
  }, [uid, run])

  return <SimplePage icon='user' centered title={user.email} loading={loading} error={error}>
    <Header as='h3'>Notebooks</Header>
    <Notebooks user={user}/>
  </SimplePage>
}

export const Notebooks = ({user}) => {
  const [loading, error, run, notebooks] = useRequest([])

  useEffect(()=>{
    if (user && user.id) {
      run(API.getUserNotebooks(user.id))
    }
  }, [run, user])

  if (loading) {
    return <Loader inline active/>
  }

  if (error) {
    return <Message warning content={error}/>;
  }

  return <>
    {notebooks.map(({title, id})=>{
      return <Button as={Link} key={id} to={`/u/${user.id}/n/${id}`} content={title} />
    })}
  </>
}
