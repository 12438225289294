import React, { useCallback } from 'react'
import { Form, Message } from 'semantic-ui-react'

import API from 'Api'
import { useRequest, useFields } from 'Shared/Hooks';

import { defaultPrompt, PromptFormFields } from 'Shared/PromptForm';

const SignUpForm = () => {
  const [loading, error, run, result] = useRequest({})
  const [fields, handleChange] = useFields({
    user: {email: ''},
    prompt: defaultPrompt,
  })

  const handleSubmit = useCallback(() => {
    run(API.signup(fields))
  }, [run, fields])

  if (result && result.success) {
    return <>
      <Message positive>Check your email to find a verification link!</Message>
      <Message warning>Be aware that this first email may land in your spam folder, especially if you use gmail!</Message>
    </>
  }

  const {email} = fields.user;

  return (
    <Form error name="signup" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>

      <PromptFormFields
        editing={true}
        size='huge'
        name='prompt'
        onChange={handleChange}/>

      <div style={{textAlign:'center',marginBottom:'3em'}}>
        <Form.Input
          inline
          style={{minWidth:'20em'}}
          name="user.email"
          type="email"
          placeholder="Your Email"
          required
          value={email}
          onChange={handleChange} />
        <Form.Button primary type="submit">Send Me Writing Prompts</Form.Button>
      </div>
    </Form>
  )
}

export default SignUpForm;
