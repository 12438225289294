import { Header } from "semantic-ui-react"
import { SimplePage } from "Shared/SimplePage"

export const Contact = () => {
  return <SimplePage centered icon='mail outline' title='Contact'>
    <Header as='h3'>Bug Reports</Header>
    <p>Please <a href="mailto:karl@emailnotebook.com?subject=Email Notebook Bug Report">email me</a> if you run into issues while using this service!</p>

    <Header as='h3'>Feature Requests</Header>
    <p>Please <a href="mailto:karl@emailnotebook.com?subject=Email Notebook Feature Request">email me</a> if you have ideas about how I could improve this service!</p>
  </SimplePage>
}
