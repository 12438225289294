import { Header } from "semantic-ui-react"
import { SimplePage } from "Shared/SimplePage"

export const Privacy = () => {
  return <SimplePage icon='lock' centered title='Privacy Policy'>
    <Header as='h4'>I Will...</Header>
    <ul>
      <li>Use your email address to send the prompts you configure, and other emails you request.</li>
      <li>Occasionally send you emails about product updates, feature changes, special offers, etc. Not more than a few times per year.</li>
    </ul>
    <Header as='h4'>I Will Not...</Header>
    <ul>
      <li>Sell or give your email address to any third parties other than our transactional email service provider, <a href="https://www.twilio.com/legal/privacy#sendgrid-services">SendGrid</a>.</li>
      <li>Use third-party adware tracking tools, like Google Analytics.</li>
      <li>Read your notes, except as needed to debug errors and provide support.</li>
    </ul>
  </SimplePage>
}
