import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useParams } from 'react-router'
import { Button, Loader, Message } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'

import API from 'Api'
import { User } from 'Shared/UserContext'
import { useFields, useRequest } from 'Shared/Hooks';
import { SimplePage } from 'Shared/SimplePage'

const Verify = () => {
  const { code, n } = useParams();
  const [loading, error, run, result] = useRequest({})
  const [redirect, setRedirect] = useState(false)
  const { user, userLoading, setUser } = useContext(User)

  // err is a retryable err, so is different from the useRequest error
  const [err, setErr] = useState(false)
  const [fields, , setFields] = useFields()

  useEffect(() => {
    if (!userLoading) {
      // wait until defailt whoami returns before attempting reset
      run(API.verify({code, n}), ({err, user, redirect}) => {
        if (err) {
          // this is an unusual API error that passes data to be used in a retry
          setErr(err);
          setFields({
            email: user.email,
            redirect
          });
        } else {
          setUser(user);
          setRedirect(redirect ? redirect : `/u/${user.id}` );
        }
      })
    }
  }, [run, user, setUser, setRedirect, code, n, userLoading, setFields])

  if (redirect) {
    return <Redirect to={redirect}/>
  }

  return (
    <SimplePage icon='sign in' title='Logging In' centered loading={loading} error={error}>
      {err ? <Retrier err={err} fields={fields}/> : false}
      {result && result.id ? <Message positive>You've logged in successfully.</Message> : false}
    </SimplePage>
  );
}

export default Verify;

const Retrier = ({err, fields}) => {
  const [e, setE] = useState(err)
  const [loading, error, run, result] = useRequest(false)

  const retry = useCallback(() => {
    setE(false)
    run(API.login(fields));
  }, [run, fields])

  if (loading) {
    return <Loader inline active/>
  }

  if (error) {
    return <Message warning content={error}/>
  }

  if (result) {
    return <Message success>Check your email for an updated Login Link.</Message>
  }

  return <>
    <Message warning content={e}/>
    <Button primary onClick={retry} content='Send a new Login Link'/>
  </>
}
