import React, { useContext } from 'react'
import { Redirect, Switch, useParams } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import { SimplePage } from 'Shared/SimplePage'
import Notes from './Notes'
import { MenuLink } from 'Nav/Nav'
import { PrivateRoute } from 'Routes/Helpers'
import { NotebookForm } from './NotebookForm'
import { Notebook, WithNotebook } from 'Shared/NotebookContext'
import Prompts from 'Routes/Prompts'
import NoteForm from './NoteForm'

import './notebook.less'

const NotebookWrap = () => {
  const {nid} = useParams()
  return <WithNotebook nid={nid}>
    <NotebookInner/>
  </WithNotebook>
}

const NotebookInner = () => {
  const {notebook, loading, error} = useContext(Notebook)
  const {uid, nid} = useParams()

  return (
    <SimplePage icon='book' centered title={notebook.title} loading={loading} error={error}>
      <Menu pointing secondary className='notebook-menu'>
        <Menu.Item exact as={MenuLink} to={`/u/${uid}/n/${nid}/notes`} icon='copy outline' title='Notes' content={<><span>Notes</span></>}/>
        <Menu.Item as={MenuLink} to={`/u/${uid}/n/${nid}/notes/add`} icon='pencil' title='Compose' content={<><span>Compose</span></>}/>
        <Menu.Item as={MenuLink} to={`/u/${uid}/n/${nid}/prompts`} icon='question' title='Prompts' content={<><span>Prompts</span></>}/>
        <Menu.Item as={MenuLink} to={`/u/${uid}/n/${nid}/edit`} icon='setting' title='Edit' content={<><span>Edit</span></>}/>
      </Menu>
      <Switch>
        <PrivateRoute exact path="/u/:uid/n/:nid/notes" component={Notes}/>
        <PrivateRoute exact path="/u/:uid/n/:nid/notes/add" component={NoteForm}/>
        <PrivateRoute exact path="/u/:uid/n/:nid/notes/:id" component={NoteForm}/>
        <PrivateRoute exact path="/u/:uid/n/:nid/prompts" component={Prompts}/>
        <PrivateRoute exact path="/u/:uid/n/:nid/edit" component={NotebookForm}/>
        <Redirect to={`/u/${uid}/n/${nid}/notes`}/>
      </Switch>
    </SimplePage>
  )
}

export default NotebookWrap;
