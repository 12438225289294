import React, { useState, useEffect, useCallback } from 'react'
import { Form, Message, Button } from 'semantic-ui-react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router'

import API from 'Api'
import { useRequest, useFields } from 'Shared/Hooks';

const NoteForm = () => {
  const {uid, nid, id} = useParams()
  const noteID = Number(id);
  const [loading, error, run] = useRequest({})
  const [fields, handleChange, setFields] = useFields({notebook_id: Number(nid), subject: '', clean_text: ''})
  const [redirectTo, setRedirectTo] = useState('');

  const handleSubmit = useCallback(() => {
    const action = noteID ? API.updateNote(fields) : API.createNote(fields);
    run(action, ()=>{
      setRedirectTo(`/u/${uid}/n/${nid}/notes`)
    })
  }, [noteID, uid, nid, fields, run])

  const handleDelete = useCallback(() => {
    run(API.deleteNote(noteID), ()=>{
      setRedirectTo(`/u/${uid}/n/${nid}/notes`)
    })
  }, [run, uid, nid, noteID])

  // if we have a note ID, fetch it
  useEffect(()=>{
    if (noteID) {
      run(API.getNote(noteID), note => {
        setFields(note);
      });
    }
  }, [noteID, run, setFields])

  if (redirectTo) {
    return <Redirect to={redirectTo}/>
  }

  const {subject, clean_text} = fields;

  return (
    <Form error name="create-note" loading={loading} onSubmit={handleSubmit}>
      <Message error>{error}</Message>
      <Form.Input
        name="subject"
        size='large'
        style={{maxWidth: '30em'}}
        placeholder="Note 'Title'"
        value={subject}
        onChange={handleChange} />
      <Form.TextArea
        name="clean_text"
        rows={10}
        placeholder="Note Body"
        required
        value={clean_text}
        onChange={handleChange} />
      <Button primary type="submit">Save</Button>
      {noteID ? <Button negative type="button" onClick={handleDelete}>Delete</Button> : false }
    </Form>
  )
}

export default NoteForm;
